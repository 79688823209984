<template>
    <v-autocomplete
        v-model="campaignsSelected"
        :items="campaigns"
        item-value="id"
        item-text="label"
        return-object
        label="CAMPAIGNS"
        placeholder="All Campaigns"
        multiple
        required
        :class="{
            'v-input--has-selection': campaignsSelected.length,
            'styled-field campaign-select' : true
        }"
        :loading="loading">
        <template #selection="{ index }">
            <span
                v-if="index === 0"
                class="campaign-selections">
                {{ campaignsSelected.length }} Selected
            </span>
        </template>
    </v-autocomplete>
</template>

<script>
import { SET_FB_DEALER_CAMPAIGNS_SELECTED } from '@/store/mutation-types';
import { mapState } from 'vuex';


export default {
    computed: {
        ...mapState({
            campaigns: (state) => state.metrics.facebook.ads.campaigns,
            loading: (state) => state.metrics.facebook.ads.campaignsLoading
        }),
        campaignsSelected: {
            get() {
                return this.$store.state.metrics.facebook.ads.campaignsSelected;
            },
            set(value) {
                this.$store.commit(SET_FB_DEALER_CAMPAIGNS_SELECTED, value);
            }
        }
    }
};
</script>