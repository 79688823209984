<template>
    <v-select
        v-model="interval"
        :items="intervals"
        label="INTERVAL"
        class="styled-field" />
</template>

<script>
import { INTERVALS } from '@/helpers/globals';
import { SET_INTERVAL } from '@/store/mutation-types';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            INTERVALS
        };
    },
    computed: {
        ...mapGetters({
            daysBetweenDateRange: 'metrics/daysBetweenDateRange'
        }),
        interval: {
            get() {
                return this.$store.state.metrics.interval;
            },
            set(value) {
                this.$store.commit(SET_INTERVAL, value);
            }
        },
        intervals() {
            return [ ...INTERVALS ];/*.map(interval => {
                // DISABLED Until other channels support weekly interval

                // Set the day option to disabled if the date range
                // is greater than 90 days
                // interval.disabled = (
                //     interval.value == INTERVAL_DAY &&
                //     this.daysBetweenDateRange > 90
                // );

                return interval;
            });*/
        }
    }
};
</script>