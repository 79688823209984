<template>
    <div class="attribution-field layout align-center">
        <v-menu
            :close-on-content-click="false"
            offset-y>
            <template #activator="{ on }">
                <v-text-field 
                    :value="displayedValue"
                    class="styled-field"
                    :label="label"
                    readonly
                    v-on="on" />
            </template>
            <div class="attribution-menu">
                <table>
                    <tr>
                        <th />
                        <th v-if="hasColumn(WINDOW_1_DAY)">
                            1 Day
                        </th>
                        <th v-if="hasColumn(WINDOW_7_DAY)">
                            7 Days
                        </th>
                        <th v-if="hasColumn(WINDOW_28_DAY)">
                            28 Days
                            <span v-if="!!$slots['disclaimer']">
                                *
                            </span>
                        </th>
                    </tr>
                    <tr>
                        <td>View</td>
                        <td v-if="windows[TYPE_VIEW].includes(WINDOW_1_DAY)">
                            <styled-radio
                                :value="viewWindow"
                                :input-value="WINDOW_1_DAY"
                                @input="handleViewChange(WINDOW_1_DAY)" />
                        </td>
                        <td v-else-if="hasColumn(WINDOW_1_DAY)">
                            -
                        </td>
                        <td v-if="windows[TYPE_VIEW].includes(WINDOW_7_DAY)">
                            <styled-radio
                                :value="viewWindow"
                                :input-value="WINDOW_7_DAY"
                                @input="handleViewChange(WINDOW_7_DAY)" />
                        </td>
                        <td v-else-if="hasColumn(WINDOW_7_DAY)">
                            -
                        </td>
                        <td v-if="windows[TYPE_VIEW].includes(WINDOW_28_DAY)">
                            <styled-radio
                                :value="viewWindow"
                                :input-value="WINDOW_28_DAY"
                                @input="handleViewChange(WINDOW_28_DAY)" />
                        </td>
                        <td v-else-if="hasColumn(WINDOW_28_DAY)">
                            -
                        </td>
                    </tr>
                    <tr>
                        <td>Click</td>
                        <td v-if="windows[TYPE_CLICK].includes(WINDOW_1_DAY)">
                            <styled-radio
                                :value="clickWindow"
                                :input-value="WINDOW_1_DAY"
                                @input="handleClickChange(WINDOW_1_DAY)" />
                        </td>
                        <td v-else-if="hasColumn(WINDOW_1_DAY)">
                            -
                        </td>
                        <td v-if="windows[TYPE_CLICK].includes(WINDOW_7_DAY)">
                            <styled-radio
                                :value="clickWindow"
                                :input-value="WINDOW_7_DAY"
                                @input="handleClickChange(WINDOW_7_DAY)" />
                        </td>
                        <td v-else-if="hasColumn(WINDOW_7_DAY)">
                            -
                        </td>
                        <td v-if="windows[TYPE_CLICK].includes(WINDOW_28_DAY)">
                            <styled-radio
                                :value="clickWindow"
                                :input-value="WINDOW_28_DAY"
                                @input="handleClickChange(WINDOW_28_DAY)" />
                        </td>
                        <td v-else-if="hasColumn(WINDOW_28_DAY)">
                            -
                        </td>
                    </tr>
                </table>
                <div 
                    v-if="!!$slots['disclaimer']"
                    class="attribution-field-disclaimer">
                    <slot name="disclaimer" />
                </div>
            </div>
        </v-menu>
    </div>
</template>

<script>
import { isEqual } from 'lodash';
import StyledRadio from '@/components/globals/StyledRadio';

const TYPE_VIEW = 'view';
const TYPE_CLICK = 'click';
const WINDOW_1_DAY = '1d';
const WINDOW_7_DAY = '7d';
const WINDOW_28_DAY = '28d';

export default {
    components: {
        StyledRadio
    },
    props: {
        value: {
            type: Object,
            default: () => ({
                [TYPE_VIEW]: WINDOW_1_DAY,
                [TYPE_CLICK]: WINDOW_7_DAY
            })
        },
        windows: {
            type: Object,
            required: true,
            validator(value) {
                return (
                    value.view instanceof Array && 
                    value.click instanceof Array
                );
            }
        },
        label: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            initializing: false,
            viewWindow: null,
            clickWindow: null,
            TYPE_VIEW,
            TYPE_CLICK,
            WINDOW_1_DAY,
            WINDOW_7_DAY,
            WINDOW_28_DAY
        };
    },
    computed: {
        displayedValue() {
            const values = [];
            if (this.viewWindow) {
                values.push(`${this.viewWindow} view`);
            }

            if (this.clickWindow) {
                values.push(`${this.clickWindow} click`);
            }

            return values.join(' / ');
        },
        formattedValue() {
            return {
                [TYPE_VIEW]: this.viewWindow,
                [TYPE_CLICK]: this.clickWindow
            };
        }
    },
    watch: {
        value(newValue, oldValue) {
            if (!isEqual(newValue, oldValue)) {
                this.syncValue();
            }
        },
        formattedValue(value) {
            if (!this.initializing) {
                this.$emit('input', value);
            }
        }
    },
    async created() {
        this.initializing = true;
        this.syncValue();
        await this.$nextTick();
        this.initializing = false;
    },
    methods: {
        handleViewChange(window) {
            if (window == this.viewWindow && !this.clickWindow) {
                this.$flash('At least one option must be selected', 'red');
            } else if (window == this.viewWindow) {
                this.viewWindow = '';
            } else {
                this.viewWindow = window;
            }
        },
        handleClickChange(window) {
            if (window == this.clickWindow && !this.viewWindow) {
                this.$flash('At least one option must be selected', 'red');
            } else if (window == this.clickWindow) {
                this.clickWindow = '';
            } else {
                this.clickWindow = window;
            }
            
        },
        hasColumn(window) {
            return (
                this.windows[TYPE_VIEW].includes(window) ||
                this.windows[TYPE_CLICK].includes(window)
            );
        },
        syncValue() {
            const { view, click } = this.value;
            this.viewWindow = view;
            this.clickWindow = click;
        }
    }
};
</script>

<style lang="scss" scoped>
.attribution-menu {
    background-color: $white;
    padding: 20px;
    table {
        border-collapse: collapse;
        width: 100%;
        font-size: 12px;
        th {
            color: $blue-primary;
            font-weight: 600;
            text-transform: uppercase;
            text-align: center;
            width: 75px;
            padding-bottom: 14px;
        }
        tr:nth-child(3) {
            td {
                border-top: 1px solid $gray-light;
            }
        }
        tr:nth-child(2) td {
            padding: 0 10px 10px;
        }
        tr:nth-child(3) td {
            padding: 10px 10px 0;
        }
        td {
            text-align: center;
            
            &:first-child {
                text-transform: uppercase;
                color: $gray-dark;
                text-align: left;
            }
            &:not(:last-child) {
                border-right: 1px solid $gray-light;
            }
        }
    }
}
</style>

<style lang="scss">
.attribution-field-disclaimer {
    margin-top: 20px;
    font-size: 10px;
    text-align: center;
}
</style>