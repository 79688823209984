import axios from 'axios';
import store from './store';

class PdfService {
    constructor() {
        // @todo use the same axios instance as in http.js
        this.axios = axios.create();
        this.axios.defaults.baseURL = process.env.VUE_APP_MVP_API_PUBLIC_BASE_URI.replace(new RegExp(/\/$/, 'g'), '') + '/api/v1';
        this.axios.interceptors.request.use(
            config => {
                config.headers = {
                    'Authorization': `Bearer ${store.state.user.token}`,
                };
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );
    }

    async getReport({ templateId, dealerId, startDate, endDate, campaignIds = [], appBaseUrl = null }) {
        const config = {
            method: 'POST',
            url: `custom_reports/${templateId}/pdf`,
            headers: {
                Accept: 'application/pdf'
            },
            responseType: 'blob',
            data: {
                dealer_id: dealerId,
                start_date: startDate,
                end_date: endDate,
                campaign_ids: campaignIds,
                app_base_url: appBaseUrl ?? window.location.origin,
                token: store.state.user.token
            }
        };

        const response = await this.axios.request(config);
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        link.download='report.pdf';
        link.click();
    }
}

export default PdfService;