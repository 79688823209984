/* eslint-disable max-len */
import HttpClient from '@/http';

/**
 * This class interacts with the custom reports endpoint
 * to save and retrieve custom report templates
 * for use with the report builder
 */
class TemplatesApi {

    /**
     * Primary constructor
     */
    constructor() {
        this.http = new HttpClient();
    }

    /**
     * Retrieves all custom report templates by agency ID
     *
     * @param {object} params
     */
    async getAgencyTemplates() {
        const response = await this.http.get('custom_reports', { with_relationships: 'custom_reports.agency', page_size: 1000 });
        return response.data.data;
    }

    async createBlankTemplate({ agencyId, name }) {
        const config = {
            name,
            pages: [
                {
                    title: 'Front Cover',
                    preconfigured: true,
                    meta: {
                        branded: false,
                        layout: 'FrontCover',
                        background: 'connecting-nodes',
                        required_feature: null
                    },
                    slots: [
                        { name: 'title', type: 'text', eval: 'brand.template_name' },
                        { name: 'account', type: 'text', eval: 'dealer.name' },
                        { name: 'date-range', type: 'text', eval: 'dateRangeFormatted' },
                        { name: 'logo', type: 'image', eval: 'brand.logo' }
                    ]

                }
            ]
        };
        return this.createTemplate({ agencyId, name, config });
    }

    async createTemplate({ agencyId, name, config }) {
        const template = {
            agency_id: agencyId,
            display_name: name,
            configuration: config,
            public: true
        };
        const response = await this.http.post('custom_reports', template);
        return response.data;
    }

    async getTemplate(templateId) {
        const response = await this.http.get(`custom_reports/${templateId}`);
        return response.data.data;
    }

    async updateTemplate({ templateId, data }) {
        const response = await this.http.put(`custom_reports/${templateId}`, data);
        return response.data;
    }

    async deleteTemplate(templateId) {
        const response = await this.http.delete(`custom_reports/${templateId}`);
        return response.data;
    }
}

export default TemplatesApi;