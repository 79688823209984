var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"attribution-picker"},[_c('attribution-field',{staticClass:"attribution-picker-field",attrs:{"windows":{
            view: ['1d', '7d', '28d'],
            click: ['1d', '7d', '28d']
        },"label":"WEBSITE ATTRIBUTION"},model:{value:(_vm.websiteAttribution),callback:function ($$v) {_vm.websiteAttribution=$$v},expression:"websiteAttribution"}}),_c('attribution-field',{staticClass:"attribution-picker-field",attrs:{"windows":{
            view: ['1d', '7d', '28d'],
            click: ['1d', '7d', '28d']
        },"label":"OFFLINE ATTRIBUTION"},model:{value:(_vm.offlineAttribution),callback:function ($$v) {_vm.offlineAttribution=$$v},expression:"offlineAttribution"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }