<template>
    <v-checkbox
        v-model="spendOverrideDisable"
        :true-value="false"
        :false-value="true"
        class="styled-checkbox-small print-hidden gray--text"
        label="Spend override" />
</template>

<script>
import { SET_SPEND_OVERRIDE_DISABLE } from '@/store/mutation-types';

export default {
    computed: {
        spendOverrideDisable: {
            get() {
                return this.$store.state.metrics.spendOverrideDisable;
            },
            set(value) {
                this.$store.commit(SET_SPEND_OVERRIDE_DISABLE, value);
            }
        }
    }
};
</script>