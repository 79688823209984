<template>
    <styled-slideout
        :value="value"
        create-on-open
        @input="$emit('input', $event)">
        <div class="pa-5">
            <v-alert
                :value="complete"
                type="success"
                class="mb-4">
                We have successfully saved these settings!
            </v-alert>

            <v-alert
                :value="error"
                type="error"
                class="mb-4">
                {{ error }}
            </v-alert>

            <h2>Advertising Settings</h2>
            <p>Use the options below to modify the way this report behaves</p>

            <v-form
                v-model="valid"
                @submit.prevent="handleSave">
                <v-checkbox
                    v-model="spendOverrideActive"
                    label="Override Spend Calculation"
                    color="primary"
                    class="styled-checkbox mb-3" />
                <div
                    v-if="spendOverrideActive"
                    class="ml-3 mb-3">
                    <v-radio-group
                        v-model="spendOverrideType"
                        label="Calculation Type:"
                        class="styled-radio mb-3"
                        @change="handleTypeChange">
                        <styled-tooltip
                            v-for="(type, index) in spendOverrideTypes"
                            :key="type.id"
                            high-index>
                            <template #content>
                                {{ type.description }}
                            </template>
                            <v-radio
                                :value="type.value"
                                :label="type.label"
                                color="primary"
                                :class="{
                                    'mb-2': index !== (spendOverrideTypes.length - 1)
                                }" />
                        </styled-tooltip>
                    </v-radio-group>
                    <div class="layout">
                        <v-text-field
                            v-if="spendOverrideInputActive"
                            v-model="spendOverrideValue"
                            label="Value"
                            hint="Enter the value"
                            class="mb-3 styled-field flex xs12 md6"
                            :rules="spendOverrideValueRules">
                            <template
                                v-if="currentType.valuePrepend"
                                #prepend>
                                {{ currentType.valuePrepend }}
                            </template>
                            <template
                                v-if="currentType.valueAppend"
                                #append>
                                {{ currentType.valueAppend }}
                            </template>
                        </v-text-field>
                    </div>
                </div>
                <div class="text-xs-center">
                    <styled-button
                        :disabled="!valid"
                        :loading="loadingSave"
                        class="mt-4">
                        SAVE SETTINGS
                    </styled-button>
                </div>
            </v-form>
        </div>
    </styled-slideout>
</template>

<script>
import StyledTooltip from '@/components/globals/StyledTooltip';
import StyledSlideout from '@/components/globals/StyledSlideout';
import StyledButton from '@/components/globals/StyledButton';
import { currencyRules, requiredRules, percentageRules } from '@/helpers/validationRules';
import { mapGetters, mapState } from 'vuex';

export default {
    components: {
        StyledTooltip,
        StyledSlideout,
        StyledButton
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            complete: false,
            error: '',
            valid: false,
            loadingSave: false,
            spendOverrideActive: false,
            spendOverrideType: null,
            spendOverrideValue: null,
            spendOverrideTypes: [{
                label: 'Percentage of Spend',
                value: 'percentage',
                hasInput: true,
                description: 'Multiplies the actual ad spend by one plus the fractional percentage.',
                equation: '(spend * (1 + (percent / 100))',
                valueAppend: '%',
                valuePrepend: null,
                rules() {
                    return [
                        ...requiredRules,
                        ...percentageRules
                    ];
                }
            },{
                label: 'Fixed Monthly Value',
                value: 'monthly_fixed',
                hasInput: true,
                description: 'Applies a fixed monthly spend and pro-rates it based on the fraction of each represented month in the selected date range.  This option ignores the actual spend entirely.',
                equation: '(fixed monthly value) * ((total days selected) / (total days across selected months))',
                valueAppend: null,
                valuePrepend: '$',
                rules() {

                    //TODO: Make this dynamic once it's supported in the reports
                    const country = 'US';

                    return [
                        ...requiredRules,
                        ...currencyRules(country)
                    ];
                }
            },{
                label: 'No Spend',
                value: 'no_spend',
                hasInput: false,
                description: 'Hides all spend-related values from reports (e.g. spend, cost per X, etc)',
                equation: null,
                valueAppend: null,
                valuePrepend: null,
                rules: []
            }]
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer
        }),
        ...mapGetters([
            'dealerAdSpendOverride'
        ]),
        spendOverrideInputActive() {
            return this.spendOverrideType && this.currentType.hasInput;
        },
        currentType() {
            return this.spendOverrideTypes.find(type => this.spendOverrideType == type.value);
        },
        spendOverrideValueRules() {
            return this.currentType.rules.call(null, this.currentDealer);
        }
    },
    watch: {
        currentDealer() {
            this.syncSettings();
        }
    },
    created() {
        this.syncSettings();
    },
    methods: {
        syncSettings() {
            this.spendOverrideActive = !!this.dealerAdSpendOverride;

            // Sync the settings in from the current dealer
            if (this.dealerAdSpendOverride) {
                this.spendOverrideType = this.dealerAdSpendOverride.type;
                this.spendOverrideValue = this.dealerAdSpendOverride.value;
            }
        },
        handleTypeChange() {
            // Reset the value when the type changes
            this.spendOverrideValue = '';
        },
        async handleSave() {
            try {
                this.loadingSave = true;
                this.complete = false;
                this.error = '';

                const config = {};

                if (this.spendOverrideActive) {
                    config.facebook_ad_spend_override = {
                        type: this.spendOverrideType,
                        value: this.spendOverrideValue
                    };
                } else {
                    config.facebook_ad_spend_override = null;
                }

                await this.$apiRepository.updateDealerConfiguration(this.currentDealer.id, config);

                await this.$store.dispatch('updateCurrentDealer');

                this.complete = true;

            } catch (error) {
                console.error('Error saving advertising settings', error.response || error);
                this.error = 'Error saving advertising settings.  Please try again or contact support for assitance.';
            } finally {
                this.loadingSave = false;
            }
        }
    }
};
</script>